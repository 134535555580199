<template>
  <v-card>
    <v-card-title>
      <p class="title font-weight-regular">Открытые билеты</p>
      <v-spacer></v-spacer>
      <v-text-field append-icon="search" label="ГРНЗ" single-line @input="onSearch"></v-text-field>
      <v-spacer></v-spacer>
      <v-btn @click="addDialog = true" icon color="orange">
        <v-icon>add</v-icon>
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="!selected.length" @click="deleteDialog = true" icon color="red" v-bind="attrs" v-on="on">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </template>
        <span>Удалить выделенные объекты</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="!selected.length" @click="unSelect" icon color="red" v-bind="attrs" v-on="on">
            <v-icon>mdi-selection-remove</v-icon>
          </v-btn>
        </template>
        <span>Убрать выделение со всего</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-data-table v-model="selected" :headers="headers" :footer-props="footerProps" :items="tickets" item-key="id"
        show-select @input="onSelect">
        <template v-slot:item.score="{ item }">
          <v-chip :color="getScoreColor(item.score)" text-color="white">
            <v-icon v-if="item.isChanged" class="mx-n1">done</v-icon>
            <template v-else>{{ item.score }}</template>
          </v-chip>
        </template>
        <template v-slot:item.detail="{ item }">
          <v-btn icon @click="openTicketDetail(item.id)">
            <v-icon>wysiwyg</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.dateTime="{ item }">
          {{ item.dateTime | fulldate }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-2 d-flex justify-space-between" primary-title>
          <span>Удаление билетов</span>
        </v-card-title>
        <v-card-text class="text--primary mt-3">
          <p class="subtitle-1">
            Вы действительно хотите удалить {{ selected.length }} билетов?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="onDelete">Да</v-btn>
          <v-btn text @click="deleteDialog = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialog" width="400">
      <TicketAdd v-if="addDialog" @close="addDialog = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import commonMixin from "@/mixins/commonMixins";
import TicketAdd from "./TicketAdd";

export default {
  mixins: [commonMixin],
  components: { TicketAdd },
  data() {
    return {
      headers: [
        { text: "Точность", value: "score" },
        { text: "Дата и время заезда", value: "dateTime" },
        { text: "ГРНЗ", value: "plate" },
        { text: "", value: "detail" },
      ],
      footerProps: {
        "items-per-page-options": [5, 10, 15, 20],
      },
      deleteDialog: false,
      addDialog: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters("tickets", {
      tickets: "filteredTickets",
    }),
    ...mapState({
      selectedTicket: (state) => state.tickets.selectedTicket,
    }),
  },
  watch: {
    selectedTicket: function (val) {
      if (val == null && this.selected <= 1) {
        this.selected = []
      }
    },
  },
  methods: {
    ...mapActions("tickets", [
      "fetchTickets",
      "filterTickets",
      "deleteTickets",
      "openTicketDetail",
      "selectTicket",
    ]),
    onSearch(value) {
      this.filterTickets(value.toUpperCase());
    },
    onSelect(values) {
      this.selectTicket(values.length === 1 ? values[0] : null);
    },
    unSelect() {
      this.selected = []
    },
    onDelete() {
      this.deleteTickets(this.selected.map((t) => t.id)).then(() => {
        this.selected = [];
        this.deleteDialog = false;
      });
    },
  },
  created() {
    this.fetchTickets();
  },
};
</script>