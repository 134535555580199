<template>
  <v-app>
    <v-app-bar app clipped-left dense>
      <v-app-bar-nav-icon @click.stop="toggleNavigation"></v-app-bar-nav-icon>
      <v-toolbar-title
        class="headline font-weight-light mr-10 d-none d-sm-block"
      >
        <span class="green--text">Mega</span>
        <span class="primary--text">Pass</span>
        <span class="red--text"> Operator</span>
      </v-toolbar-title>
      <v-spacer />
      <span class="black--text headline font-weight-light">{{ parkingName }}</span>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab :to="{ name: 'dashboard' }">Камеры</v-tab>
          <v-tab :to="{ name: 'mismatches' }">Билеты</v-tab>
        </v-tabs>
      </template>
      <v-toolbar-items></v-toolbar-items>
      <div class="flex-grow-1"></div>
      <v-btn text class="font-weight-regular" @click="logout">
        <span class="d-none d-sm-block">Выход</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- must be declared only once in app components -->
    <Snackbar />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Snackbar from "@/components/Snackbar";

export default {
  components: { Snackbar },
  data() {
    return {
      connection: null,
    };
  },
  computed: {
    ...mapState({
      parkingName: (state) => state.parking.parking.name,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("tickets", ["fetchTickets", "addTicket", "removeTicket"]),
    ...mapActions("entries", ["fetchEntries", "addEntry", "removeEntry"]),
    ...mapActions("exits", ["fetchExits", "addExit", "removeExit"]),
    ...mapActions("unExits", ["addUnExit"]),
    ...mapActions("parking", ["fetchParking"]),
  },
  created() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_BASE_URL}/hubs/operator`, {
        accessTokenFactory: () => localStorage.getItem("token"),
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();
  },
  mounted() {
    this.fetchParking();
    this.fetchTickets();
    this.fetchEntries();
    this.fetchExits();

    this.connection.start();

    this.connection.on("add-ticket", (ticket) => this.addTicket(ticket));
    this.connection.on("add-entry", (entry) => this.addEntry(entry));
    this.connection.on("add-exit", (exit) => this.addExit(exit));
    this.connection.on("add-un-exit", (unExit) => this.addUnExit(unExit));

    this.connection.on("remove-ticket", (ticket) => this.removeTicket(ticket));
    this.connection.on("remove-entry", (entry) => this.removeEntry(entry));
    this.connection.on("remove-exit", (exit) => this.removeExit(exit));
  },
};
</script>