<template>
  <v-card>
    <v-card-title>
      <span>Тикеты</span>
      <v-text-field
        append-icon="search"
        label="Поиск"
        single-line
        hide-details
        @input="onSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :items="tickets"
      :items-per-page="10"
      :footer-props="footerProps"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            @click="openTicketDetail(item.id)"
          >
            <td>
              <template v-if="item.epc && !item.plate">
                <v-chip color="blue" dark>
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  RFID
                </v-chip>
              </template>
              <template v-else>
                <v-chip :color="getScoreColor(item.score)" dark>
                  <v-avatar left v-if="item.isChanged">
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  {{ item.plate }}
                </v-chip>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  data() {
    return {
      footerProps: {
        "items-per-page-options": [5, 10, 15, 20],
      },
    };
  },
  computed: {
    ...mapGetters("tickets", {
      tickets: "filteredTickets",
    }),
  },
  methods: {
    onSearch(value) {
      this.filterTickets(value.toUpperCase());
    },
    ...mapActions("tickets", ["openTicketDetail", "filterTickets"]),
  },
};
</script>