<template>
  <v-card v-if="ticket">
    <v-card-title class="headline grey lighten-2 d-flex justify-space-between" primary-title>
      <span>Тикет #{{ ticket.number }}</span>
      <span class="font-weight-light">{{
        ticket.entryDateTime | fulldate
      }}</span>
    </v-card-title>

    <v-card-text class="text--primary mt-3">
      <v-row>
        <v-col cols="8">
          <template v-if="ticket.image">
            <v-img :src="`data:image/jpeg;base64,${ticket.image}`"></v-img>
          </template>
          <template v-else>
            <p class="ml-4 mr-4 mt-10 mb-10 display-3 font-weight-thin text-center error--text">
              Изображение отсутствует
            </p>
          </template>
        </v-col>
        <v-col cols="4">
          <v-text-field label="ГРНЗ" v-model="ticket.plate" append-icon="done" outlined
            @click:append="changePlate({ id: ticket.id, plate: ticket.plate })"
            @keyup.enter="changePlate({ id: ticket.id, plate: ticket.plate })"></v-text-field>
          <v-slider label="Процент распознания" v-model="ticket.score" thumb-label="always" :thumb-size="24"
            :color="getScoreColor(ticket.score)" readonly></v-slider>
          <p class="subtitle-1">EPC: {{ ticket.epc || "отсутствует" }}</p>
          <p class="subtitle-1">
            <v-chip v-if="ticket.hasPass" variant="elevated" size="large" color="green" text-color="white">
              <h4>Пропуск</h4>
            </v-chip>
            <v-chip v-else-if="ticket.isGuest" variant="elevated" size="large" color="orange" text-color="white">
              <h4>Гость</h4>
            </v-chip>
            <v-chip v-else variant="elevated" size="large" color="blue" text-color="white">
              <h4>Посетитель</h4>
            </v-chip>
          </p>
          <p class="subtitle-1">Линия въезда: {{ ticket.entryLane }}</p>
          <p class="subtitle-1">Сумма к оплате: {{ ticket.amountToPay | money }} тг</p>
          <p class="subtitle-1">Оплачено: {{ ticket.paidAmount | money }} тг</p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeTicketDetail">Закрыть</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  data() {
    return {
      plateToMatch: "",
    };
  },
  computed: {
    ...mapState({
      ticket: (state) => state.tickets.ticketDetail,
    }),
    ...mapState({
      exits: (state) => state.exits.exitsToMatch,
    }),
  },
  methods: {
    ...mapActions("tickets", [
      "closeTicketDetail",
      "changePlate",
    ]),
  },
};
</script>

<style></style>