<template>
  <v-card v-if="selectedTicket && selectedUnExit">
    <v-card-title class="headline grey lighten-2 d-flex justify-space-between" primary-title>
      <span>Выберите ГРНЗ</span>
      <span class="font-weight-light"></span>
    </v-card-title>

    <v-card-text class="text--primary mt-3">
      <v-radio-group v-model="selectedPlate">
        <v-radio v-for="(plate, i) in [selectedTicket.plate, selectedUnExit.plate]" :key="plate + i" :label="plate"
          :value="plate"></v-radio>
      </v-radio-group>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="apply" text color="red" :disabled="!selectedPlate">
        Применить
      </v-btn>
      <v-btn @click="close" text>Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  data() {
    return {
      selectedPlate: "",
    };
  },
  computed: {
    ...mapState({
      selectedTicket: (state) => state.tickets.selectedTicket,
      selectedUnExit: (state) => state.unExits.selectedUnExit,
    }),
  },
  methods: {
    ...mapActions("unExits", ["matchUnExit"]),
    apply() {
      const args = {
        ticketId: this.selectedTicket.id,
        plate: this.selectedPlate,
      };
      this.matchUnExit(args);
      this.close();
    },
    close() {
      this.selectedPlate = "";
      this.$emit("close");
    },
  },
};
</script>