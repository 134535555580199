<template>
  <v-row>
    <v-col cols="6"><Tickets /></v-col>
    <v-col cols="6"><UnExits /></v-col>
    <v-btn
      v-if="selectedTicket && selectedUnExit"
      @click="matchingDialog = true"
      fab
      color="red"
      absolute
      class="mt-15"
      :style="{ left: '50%', transform: 'translateX(-50%)' }"
    >
      <v-icon color="white">sync_alt</v-icon>
    </v-btn>

    <v-dialog v-model="ticketDetailDialog" width="1000">
      <TicketDetail />
    </v-dialog>
    <v-dialog v-model="unExitDetailDialog" width="1000">
      <UnExitDetail />
    </v-dialog>
    <v-dialog v-model="matchingDialog" width="300">
      <Matching @close="matchingDialog = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Tickets from "../mismatches/Tickets.vue";
import UnExits from "../mismatches/UnExits.vue";
import TicketDetail from "../TicketDetail.vue";
import UnExitDetail from "../mismatches/UnExitDetail.vue";
import Matching from "../mismatches/Matching.vue";

export default {
  components: { Tickets, UnExits, TicketDetail, UnExitDetail, Matching },
  data() {
    return {
      ticketDetailDialog: false,
      unExitDetailDialog: false,
      matchingDialog: false,
    };
  },
  computed: {
    ...mapState({
      ticketDetail: (state) => state.tickets.ticketDetail,
      unExitDetail: (state) => state.unExits.unExitDetail,
      selectedTicket: (state) => state.tickets.selectedTicket,
      selectedUnExit: (state) => state.unExits.selectedUnExit,
    }),
  },
  methods: {
    ...mapActions("tickets", ["filterTickets"]),
    ...mapActions("unExits", ["filterUnExits"]),
  },
  watch: {
    ticketDetail: function (val) {
      this.ticketDetailDialog = val !== null;
    },
    unExitDetail: function (val) {
      this.unExitDetailDialog = val !== null;
    },
  },
  mounted() {
    this.filterTickets("");
    this.filterUnExits("");
  },
};
</script>