<template>
  <v-card v-if="unExit">
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
    >
      <span>Выезд</span>
      <span class="font-weight-light">{{ unExit.dateTime | fulldate }}</span>
    </v-card-title>

    <v-card-text class="text--primary mt-3">
      <v-row>
        <v-col cols="8">
          <template v-if="unExit.image">
            <v-img :src="`data:image/jpeg;base64,${unExit.image}`"></v-img>
          </template>
          <template v-else>
            <p
              class="ml-4 mr-4 mt-10 mb-10 display-3 font-weight-thin text-center error--text"
            >
              Изображение отсутствует
            </p>
          </template>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="ГРНЗ"
            v-model="unExit.plate"
            append-icon="done"
            outlined
            readonly
          ></v-text-field>
          <v-slider
            label="Процент распознания"
            v-model="unExit.score"
            thumb-label="always"
            :thumb-size="24"
            :color="getScoreColor(unExit.score)"
            readonly
          ></v-slider>
          <p class="subtitle-1">EPC: {{ unExit.epc || "отсутствует" }}</p>
          <p class="subtitle-1">Линия въезда: {{ unExit.lane }}</p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeUnExitDetail">Закрыть</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import commonMixin from "@/mixins/commonMixins";

export default {
  mixins: [commonMixin],
  data() {
    return {
      showDialog: false,
      plateToMatch: "",
    };
  },
  computed: {
    ...mapState({
      unExit: (state) => state.unExits.unExitDetail,
    }),
  },
  methods: {
    ...mapActions("unExits", [
      "closeUnExitDetail",
    ]),
  },
};
</script>