<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
    >
      <span>Открытие шлагбаума</span>
      <span class="font-weight-light"></span>
    </v-card-title>

    <v-card-text class="text--primary mt-3">
      <v-text-field
        label="Комментарий"
        v-model="comment"
        outlined
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="open" text color="red"> Открыть </v-btn>
      <v-btn @click="close" text>Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    open() {
      this.$emit("open", this.comment);
      this.comment = "";
    },
    close() {
      this.comment = "";
      this.$emit("close");
    },
  },
};
</script>