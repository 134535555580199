var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('p',{staticClass:"title font-weight-regular"},[_vm._v("Нераспознанные выезды")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"ГРНЗ","single-line":""},on:{"input":_vm.onSearch}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selected.length,"icon":"","color":"red"},on:{"click":function($event){_vm.deleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete_forever")])],1)]}}])},[_c('span',[_vm._v("Удалить выделенные объекты")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selected.length,"icon":"","color":"red"},on:{"click":_vm.unSelect}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-selection-remove")])],1)]}}])},[_c('span',[_vm._v("Убрать выделение со всего")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"items":_vm.unExits,"item-key":"id","show-select":""},on:{"input":_vm.onSelect},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getScoreColor(item.score),"text-color":"white"}},[[_vm._v(_vm._s(item.score))]],2)]}},{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fulldate")(item.dateTime))+" ")]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openUnExitDetail(item.id)}}},[_c('v-icon',[_vm._v("wysiwyg")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 d-flex justify-space-between",attrs:{"primary-title":""}},[_c('span',[_vm._v("Удаление выездов")])]),_c('v-card-text',{staticClass:"text--primary mt-3"},[_c('p',{staticClass:"subtitle-1"},[_vm._v(" Вы действительно хотите удалить "+_vm._s(_vm.selected.length)+" выездов? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.onDelete}},[_vm._v("Да")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Отмена")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }